@import '../_master.scss';

.activity-viewer{
    max-width: 100vw !important;
    
    .steps-bloc-titre{
        display: flex;
        //position: relative;
        //border: 1px solid blue;
        height: 110px;;
        //flex-direction: column;
        margin-top: 140px;
        justify-content: flex-start;
        align-items: center;
        text-align: left !important;
        & .steps-titre{
                margin-left: 10vw;
                background-color: #6cb52d;
                //border:1px solid red;
                height: 100%;
                width: 600px;
                display: flex;
                justify-content: center;
                align-items: center;
                & h1{
                    color:white;
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                     & b{

                     }
                }
        }
        & .steps-sous-titre{
            background-color: #006b54;
            margin-right: 10vw;
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            & p{
                width:  90%;
                color: white;
                font-size: 1.2rem;
                
            }
        }
           
    }
    .divider-activité{
        //border: 1px solid red;;
        & h1{
            font-family: 'Karla', sans-serif;
            //border: 1px solid red;;
            width: auto;
            font-size: 32px;
            text-align: left;
            margin-left: 10vw;
            color:#29ABE2;
        }
    }
    .activity-container{
       // border:1px solid red;
        width: 79vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

    }
    .activité-bloc-card{
        //border-radius: 5px;
        border:none;
        filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.1));
        display: flex;
        //justify-content: flex-start;
        //align-items: center;
        height: 280px;
        width: 49%;
        margin-bottom: 2%;
        position: relative;
        & .checked-icon{
            position: absolute;
            width: 100px;
            height: 100px;
            top:90px;
            left:10%;
        }
        & .card-thumbnail{
            height: 280px;
            width: 40%;
            //border:1px solid red;
        }
        & .activité-bloc-text{
            padding-left: 0.5rem;
            width: 60%;
            height: 270px;
            color:#006b54;
            text-align: left;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
           // border:1px solid red;
            position: relative;
            //align-items: center;
            //justify-content: center;
           
            & h4{
              margin-top:10px;
              text-align: left;
              width:90%;  
              margin-bottom: 5px;
              margin-left: 10%;
            //border:1px solid red;
              padding: 0px !important;
            }
            & >div{

                width: 90%;
                height: auto;
                max-height: 200px;
                overflow: hidden;
                text-align: left;
                margin-top: 10px;
                font-size: 14px;
                margin-left: 10%;
                & >p{
                    //border:1px solid red;
                    margin-top: 0px;
                    margin-bottom: 5px;
                }
            }
            & p{
                width: 100%;
                height: auto;
                max-height: 100px;
                //border:1px solid red;
            }
            // & h4{
            //     //margin: 0;
            // }
        }
        & .button-activity{
            width: 120px;
            height: 30px;
            //padding: 3%;
            position: absolute;
            bottom: 40px;
            //margin-left:20%;
            //margin-top: 20px;
            left:35px;
            text-align: center;
            background-color: #cb531b;
            border-radius: 20px;
            //border:1px solid #ec6e51;
            color: white;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
            & .btn-arrow{
                font-size : 2em;
                color: #273a50;
                margin-left:0 .2rem;
            }
            
        }
        & .button-activity:hover{
            background-color:white !important;
            color: #cb531b;
            border:1px solid #cb531b;
        }
    }
}
.top-page-activity{
    //height: 130px;;
    //flex-direction: column;
    margin-top: 140px!important;
    width: 80%!important;
    height: 80vh;
     margin-left: 10%!important;
    text-align: left !important;
    //border: 1px solid red;
    padding: 0px !important;
    .arrow-container{
        width: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        .arrow-back{
            position: absolute;
            left: -40px;
            cursor: pointer;
            color: #bd582d;
            opacity: 0.4;
            transform:scale(1);
            transition: opacity,transform 0.6s;
            
        }
        .arrow-back:hover{
            transform:scale(2);
            opacity: 1;
        }
        .arrow-fwd{
            position: absolute;
            right: -40px;
            cursor: pointer;
            color: #bd582d;
            opacity: 0.4;
            transition: opacity,transform 0.6s;
        }
        .arrow-fwd:hover{
            transform:scale(2);
            opacity: 1;
        }
    }
    & .masthead{
        display: flex;
        align-items: center;
        justify-content: start;
        & .mast-stepper{
            //border:1px solid green;
            width:30% !important;
            margin: 0px!important;
        }
    }
    & .steps-bloc-titre{
        display: flex;
        //position: relative;
        //border: 1px solid blue;
        height: 100px;;
        //flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: left !important;
        & .steps-titre{
                //margin-left: 10vw;
                background-color: #6cb52d;
                //border:1px solid red;
                height: 100%;
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: center;
                & h1{
                    color:white;
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                     & b{

                     }
                }
        }
        & .steps-sous-titre{
            background-color: #006b54;
            //margin-right: 10vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65%;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            display: flex;
            justify-content: space-between;
            & p{
                margin-left: 20px;
                //width:  90%;
                color: white;
                font-size: 1.2rem;
                //border:1px solid white;
                
            }
            & .close-btn{
                margin-right: 20px !important;
                cursor: pointer;
                //border:1px solid white;
            }
        }
           
    }
    & .steps-back-btn{
        background-color: #cb531b !important;
        text-transform: none !important;
        color: white;
        font-weight: 700;
        border-radius:20px;
        
        width: 120px;
        height: 30px;
        //padding: 3%;
        //margin-left:20%;
        //margin-top: 20px;
        text-align: center;
        //border:1px solid #ec6e51;
        transform: background-color 1s, color 0.5s;

        & .steps-back-arrow{
            font-size : 1.2em;
            color: white;
            margin-right: 0.3rem;
        }
    }
    & .steps-back-btn:hover{
        background-color: white !important;
        border:1px solid #ec6e51;
        color: #ec6e51;
        & .steps-back-arrow{
            color:#ec6e51;
            animation: slide2 1s ease-in-out infinite;
        }
    }
    .MuiStepper-root{
        width: 70%;
        margin: auto;
    }
    .save-btn{
        margin-left: auto;
        margin-top : -1.5rem;
        background-color: #a9bc4f;
        display:flex;
        flex-direction: column;
        justify-content: center;
        width:67px;
        height:65px;
        position: absolute;
        //z-index:300;
        //bottom: -150px;
        //top:295px;
        top:31vh;
        left:90%;
        .save-icon{
            color: white !important;
            font-size: 32px;
            //justify-content: flex-end;
        }
        & p{
            font-size: 0.7rem;
        }
    }
}


.green{
    color:green;
    margin-left:10px;
}
.red{
    color:$red;
    margin-left:10px;
}
.white{
    color:white;
    margin-left:10px;
}
.info-bulle-2{
    position: absolute;
    left: 81.5%;
    filter:drop-shadow(1px 1px 4px rgba(0, 0, 0,0.3));
    width: 110px;
    top:30vh;
    z-index: 148;
    height: 45px;
    background-color: #a9bc4f;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    color: white;
   
}
@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}

@keyframes slide2 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(-10px, 0);
    }
}

@media (min-width: 1700px) {
    .activity-viewer{
        //max-width: 1700px !important;    
    }
    .top-page-activity{
        //max-width: 1700px !important;
    }
}
@media (min-width: 1800px) {
    .activity-viewer{
        //max-width: 1800px !important;    
    }
    .top-page-activity{
        //max-width: 1800px !important;
    }
}
@media (min-width: 1900px) {
    .activity-viewer{
        //max-width: 1900px !important;
    }
    .top-page-activity{
        //max-width: 1900px !important;
    }
}
@media (max-width:1100px){
    .activity-container{
        //border: 1px solid red;;
        .activité-bloc-card{
            width: 100%;
            height: 220px;
        }
    }
    .button-activity{
        bottom:110px !important ;
        left: 55px!important;
    }

}
@media (max-height:800px){
    .activity-viewer{
        //border: 1px solid red !important;
        width: 98vw!important;
        height: 100vh!important;
        overflow-y: auto !important;
        .steps-bloc-titre{
            margin-top: 110px !important;
            height: 80px;
            & .steps-sous-titre{
                & p{
                    font-size: 1rem;
                }
            }
        }
    }
    .activity-container{
        margin-top: 10px !important;
        width: 78% !important;
        height: auto;
        .activité-bloc-card{
            & .activité-bloc-text{
                & h4{
                   // border:1px solid red;
                    font-size: 1.1rem;
                }
            }

        }
        //overflow-y: scroll !important;
    }
    .top-page-activity{
        margin-top: 110px !important;
        .steps-bloc-titre{
            margin-top: 10px !important;
            height: 80px;
            & .steps-titre{
                width: 40%;
                 & h1{
                     font-size: 1.4rem;
                 }
            }
            & .steps-sous-titre{
                & p{
                    font-size: 1rem;
                }
            }
        }
    }
}
/* @media (max-width: 1100px) {
    .activity-viewer{
        .activité-bloc-card{
            & .img-activité{
                padding: 1rem;
                width: 0px;
                height: 0px;
            }
            & .activité-bloc-text{
                padding-left: 0;
            }
            & .button-socle{
                padding-left: 1.7rem;
            }
        }
    }
}
 */
