.titre-infographie{
    padding-bottom: 0;
    text-align: left !important;
    margin-top:0px;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    //border:1px solid red;
    flex-direction: column;
    align-items: flex-start;
    & > h3{
        margin: 0;
        font-style: bold;
        letter-spacing: -1.5px;
        font-size: 35px;
    }
    & p{
        margin: 0px;
        color: #34398D;
        //letter-spacing: 0.5px;
        padding-bottom: 0 !important;
        font-size: 16px;
        //border:1px solid red;
    }   
}
.content-bloc{
    width: 100%;
   // position: relative;
   height: 65vh !important;
   margin-top: 2% !important;
   border-radius: 0 !important;
    //filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2)) !important;
    background-color: white;
    display: flex;
    //border:1px solid red;
    //overflow: auto;
    & .wrapper-i{
        width: auto;
        display: flex;
        height: 100%;
        overflow: hidden;
        align-items: flex-start;
        justify-content: center;
        border: 2px solid #346857;
    }
    & .info-wrapper{
        position: relative;
        
        //display: none;
        

         
    }
    & .infographie-img{
        height: auto;
        width:auto;
            //display: none;
            margin-top: 0%;
            margin-left: 0%;
            //border: 2px solid #346857;
            //border-radius: 10px;
            background-position: center;
            background-size: contain;
            //position: relative;
            z-index:100;
            cursor: pointer;
    }
    & .content-infographie{
        text-align: left;
        //padding: 20px;
        //margin: 3%;
        //width: 60%;
        margin-left:2%;
        font-size: 16px;
        width: 100%;
        //border:1px solid red;
       // height: 100%;
        background-color: white;
        filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2)) !important;
        font-family: Verdana, Arial, Tahoma, sans-serif;
        > h3{
            color: #006b54;
            font-size: 2rem;
            font-weight: 700;
        }
        & .infographie-comment{
            //border:1px solid red;
            background-color:#006b54;
            //margin-top: 10px;
            color:white;
            padding: 15px;
            padding-top: 10px;
            margin-top: 20px;
             & ul > li {
                //border:1px solid red;
                margin-bottom: 30px;
             }
    
        }
        & .infographie-bloc-btn{
            margin-top: 30px;
            display: flex;
            justify-content: flex-start;
            padding-left: 0.6rem;
            //border: 1px solid red;;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 98%;
            & .Mui-disabled{
                opacity: 0.4;
                cursor: default;
            }
            & .button-intro{
                width: 180px;
                height: 40px;
                //padding: 3%;
                //margin-left: 6%;
                margin-bottom: 1rem;
                margin-top: 1rem;
                text-align: center;
                background-color: #bd582d;
                border-radius: 20px;
                border:1px solid #ec6e51;
                color: white;
                font-weight: 700;
                text-transform: none !important;
                transform: background-color 1s, color 0.5s;
                cursor: pointer;
            & .btn-cases-arrow{
                font-size : 1.2em;
                color: "#273a50";
                margin-left: 0.2rem;
                }
            }
            & .button-intro:hover{
                    background-color:white !important;
                    color: #bd582d;
                & .btn-cases-arrow{
                    animation: slide1 1s ease-in-out infinite;
                }
            }
            & .button-download{
                background-color:white !important;
                color: #bd582d;
                height: 40px;
                border-radius: 20px;
                border:1px solid #ec6e51;
                color: #ec6e51;
                font-weight: 700;
                line-height: 1rem;
                text-transform: none !important;
                transform: background-color 1s, color 0.5s;
            }
        }
        
    }
    
    
}

@media (max-height:800px){
    .content-infographie{
        h3{
            font-size: 1.6rem!important;
        }
    }
    .infographie-comment{
        font-size: 0.9rem !important;
    }
    .content-bloc{
        height: 62vh !important;
        & .conclusion-img{
            height: 62vh;
        }
        & .content-conclusion-incipit{
            overflow-y: auto;
            height: 90%;
        }
    }

}