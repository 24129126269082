:root {
  --wheel-font: 'Verdana', 'Arial', sans-serif;
  --wheel-size: 30vw;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 5px;
  --wheel-color: #a9bc4f;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 1;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 3s;
  --reset-duration: 0.25s;
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  margin: auto;
  background-color: var(--neutral-color);
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}

.wheel::after {
  display: block;
  position: absolute;
  content: '';
  background-color: var(--neutral-color);
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
  font-size: 1rem;
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
    Largeur du triangle
    Je ne sais pas pourquoi j'ai besoin d'ajouter 
      " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
    mais globalement ca marche a peu près bien 
    TODO: A étudier 
  */
  --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
  --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}
@media only screen and (max-height:800px){
  .wheel-item {
    font-size: 0.8rem;
  } 
}
@media only screen and (max-width:1375px) and (max-height:780px){
  :root {
    --wheel-font: 'Verdana', 'Arial', sans-serif;
    --wheel-size: 25vw;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #a9bc4f;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 1;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 3s;
    --reset-duration: 0.25s;
  }
  .wheel-item {
    font-size: 0.7rem;
  } 

}
@media only screen and (max-width:750px){
  :root {
    --wheel-font: 'Verdana', 'Arial', sans-serif;
    --wheel-size: 75vw;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #a9bc4f;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 1;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 3s;
    --reset-duration: 0.25s;
  }
  .wheel-item {
    font-size: 0.7rem;
  } 

}