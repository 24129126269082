@import '../_master.scss';

.titre-intro{
    font-style: bold;
    font-family: $font-title;
    font-size: 24px;
    text-align: left;
    display: flex;
    align-items: center; 
    & > h3{
        letter-spacing: -1.5px;
        font-size: 30px;
    }     
    & > p{
        font-size: 16px;
        color: $midgray;
        letter-spacing: 0.5px;
    } 
    
}
.content-intro{
    width: 100%;
    margin-top: 2% !important;
    height: 65vh !important;
    background-color: white !important;
    //border-radius:5px;
    filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2));
    display: flex;
    & .intro-img{
        //border: 1px solid;
        height: 65vh;
        width: 40%;
        background-position: center;
        background-size: cover;
    }
    & .content-intro-incipit{
        text-align: left;
        padding-top: 0 !important;
        width:60%;
        
        display: flex;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        flex-direction: column;
        color: #006b54;
        & h3{
            margin-left: 30px;
            font-size: 2rem;
            font-weight: 700;
        }
        & .text{
            //color: $midgray;
            margin-left: 30px;
            margin-right: 30px;
            width: 90%;
            font-size: 1rem;
            //padding-left: 0.6rem;
        }
    }
    & .bloc-content-intro{
        margin-top: 5px;
        border-radius: 15px;
        padding-top: 5px;
         & ul > li {
            margin-bottom: 10px;
         }

    }
    & .intro-bloc-btn{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        padding-left: 0.6rem;
        margin-left: 20px;
        & .button-intro{
            background-color: #cb531b !important;
            text-transform: none !important;
            color: white;
            font-weight: 700;
            border-radius:20px;
            width: 200px;
            height: 30px;
            margin-top: 1rem;
            text-align: center;
            background-color: white;
           
            color: white;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
           & .arrow-btn-intro{
                font-size : 1.2em;
                color: white;
                margin-left:0.2rem;
           }
        }
        & .button-intro:hover{
            color:white !important;
            background-color: white;
            border:1px solid #ec6e51;
            & .arrow-btn-intro{
                color:white;
                animation: slide1 1s ease-in-out infinite;
            }
        }
    }
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }

@media (min-width: 1700px) {
    .titre-intro{
        & > h3{
            //margin: 0;
            margin-top: 2rem;
        }   
        
    }
}
@media (max-height:800px){
    .content-intro{
        height: 62vh !important;
        & .intro-img{
            height: 62vh;
        }
        & .content-intro-incipit{
            overflow-y: auto;
            height: 90%;
            & h3{
                font-size: 1.6rem;
                font-weight: 700;
            }
            & .text{
                //color: $midgray;
                margin-left: 30px;
                margin-right: 30px;
                width: 90%;
                font-size: 0.9rem;
                //padding-left: 0.6rem;
            }
        }
    }

}