@import '../_master.scss';
.nav{
    width: 100vw;
    height: 140px;
    position: fixed;
    top:0;
    left: 0;
    background-color:white;
    //border:1px solid red;
    display: flex;
    //justify-content: space-evenly !important;
    align-items: center !important;
    justify-content: space-between;
    flex-grow: 1;
    color: #34398D;
    z-index: 15;
    & .module-titre{
        width: 100%;
        height:140px;   
        //border:1px solid red;
        background-image: url('../images/header-bg-2.png');
        background-position: 35vw 5px;
        background-size: 128px 130px;
        background-repeat: no-repeat;;
        //background-position:-26px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    & .bloc-marque{
        margin-left:9vw;
        width: 300px;
        height: 140px;
        background-image: url('../images/logo.png');
        background-size: contain;
        background-position: 0px 0px;
        margin-top: 0px;
        background-repeat: no-repeat;
        //border:1px solid red;
      }
    & .titre-content{
        & >h1{
            text-align: left;
            margin-top:-10px;
            padding: 0px;
            margin-left: 50px;
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 30pt;
            color: #34398d;
          }
      & p{
          margin-top:-5px;
          color: $midgray;
          margin-left: 50px;
          font-size: 14px;
          text-align: left;
          color: #34398d;
      }
    }  
    & .titre-nav{
        font-family: $font-title;
        float: left;
        text-align: left;
        margin-left: 10%;
        width: 70%;
    }
    & .menu-icons{
        justify-self:flex-end;
        text-align: right;
        margin-right:10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        //border:1px solid red;
        & .save-icon{
            margin:5px;
            .MuiIconButton-label.MuiSvgIcon-root{
                color: #cb531b !important;
            }
            color: #cb531b !important;
        }
        & .home-icon{
            color: #cb531b !important;
        }
    }
}
.tooltip{
    position:absolute;
    width: 100px;
    border-radius:10px;
    height: 40px ;
    background-color: #a9bc4f;
    color:white;
    font-size: 0.8rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 0.9rem;
    text-align:center;
    z-index: 25;
    left:-100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tooltip-2{
    position:absolute;
    width: 100px;
    border-radius:10px;
    height: 40px ;
    background-color: #a9bc4f;
    color:white;
    font-size: 0.8rem;
    line-height: 0.9rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align:center;
    z-index: 25;
    left:-50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* @media only screen and (max-width: 1500px) and (min-device-width: 480px) {
    .nav{
        .MuiTypography-body2{
            font-size: 0.7rem;
        }
    }
    
} */

@media only screen and (max-width:1150px)  {
    .nav{
        height: 100px;
    }
    .module-titre{
        height: 100px !important;
       
       
    }
    .bloc-marque{
        margin-left:7vw !important;
        width: 200px;
        height: 100px !important;
       // border: 1px solid red;
        background-image: url('../images/logo.png');
        background-size: contain;
        background-position: 0px 0px;
        margin-top: 0px;
        background-repeat: no-repeat;
        //border:1px solid red;
      }
     
   
}
@media only screen and  (max-height:800px) {
    .nav{
        height: 100px;
    }
    .module-titre{
        height: 100px !important;
        background-position: 35vw 0px !important;
        background-size: 115px 115px !important ;
       
       
    }
    .bloc-marque{
        margin-left:7vw !important;
        width: 280px;
        height: 100px !important;
       // border: 1px solid red;
        background-image: url('../images/logo.png');
        background-size: contain;
        background-position: 0px 0px;
        margin-top: 0px;
        background-repeat: no-repeat;
        //border:1px solid red;
      }
}
@media only screen and (max-width:1375px) and (max-height:780px){
    .nav{
        & .module-titre{
            width: 100%;
            height:140px;   
            //border:1px solid red;
            background-image: url('../images/header-bg-2.png');
            background-position: 41vw 5px !important;
            background-size: 110px 110px !important;
            background-repeat: no-repeat;;
            //background-position:-26px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}