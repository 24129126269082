
html {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
