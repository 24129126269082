@import '../_master.scss';
.content-video{
    width: 100%;
    margin-top: 2% !important;
    height: 65vh !important;
    background-color: white !important;
    //border-radius:5px;
    filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2));
}
.titre-video
{
    padding-bottom: 0;
    text-align: left !important;
    margin-top: -2rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > h3{
        margin: 0;
        font-style: bold;
        letter-spacing: -1.5px;
        font-size: 35px;
    }
    & p{
        margin-top: 40px;
        color: #34398D;
        //letter-spacing: 0.5px;
        padding-bottom: 0 !important;
        font-size: 16px;
        //border:1px solid red;
    }   
    
}
.video-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.incipit-video{
    font-family: Verdana, Arial, Tahoma, sans-serif;
    width: 100%;
   // border:1px solid red;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    color:#336756;
}
.video-wrapper-2{
    width: 70vw !important ;
    max-width: 700px;
    border:0.5px solid lightgray;
    margin: auto;
    //position: relative;
   // padding-top: 40%;
    border-radius: 0px !important;
    //overflow: scroll;
    //padding-top: 0;
    & .video-player{
     width: 100%;
     height: auto;
    } 

}

.video-bloc-btn{
    margin-top: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
   // border: 1px solid blue;
    & .button-video{
        width: 280px !important;
        height: 40px !important;
        //padding: 3%;
        //margin-left: 6%;
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: center;
        background-color: white;
        border-radius: 20px;
        border:1px solid #ec6e51;
        color: #ec6e51 !important;
        font-weight: 700;
        text-transform: none !important;
        transform: background-color 1s, color 0.5s;
        cursor: pointer;
       & .btn-video-arrow{
            font-size : 1.2em;
            color: #ec6e51 !important;
            margin-left:0.2rem;
       }
    }
    & .button-video:hover{
        background-color:#ec6e51 !important;
        color: white !important;
        & .btn-video-arrow{
            color:white !important;
            animation: slide1 1s ease-in-out infinite;
        }
    }
    
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}

@media (min-width: 1700px) {
    .video-wrapper{
        padding-top: 56.25%;
        border-radius: 20px !important;
    }
}
@media (max-height:800px){
    .content-video{
        height: 65vh !important;
 
    }
    .video-wrapper-2{
        width: 60vw !important;
        //max-width: 700px;
        
        
        & .video-player{
         width: 100% !important;
         height: auto;
        } 
    
    }
    .incipit-video{
       font-size:1rem;
       margin-bottom: 15px;
    }

}
@media only screen and (max-width:1375px) and (max-height:780px){
    .content-video{
        height: 62vh !important;
 
    }
    .video-wrapper-2{
        width: 40vw !important;
        //max-width: 700px;
        
        
        & .video-player{
         width: 100% !important;
         height: auto;
        } 
    
    }

}